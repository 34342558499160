import React from 'react';
import './App.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { translate } from "./utils";

const ModalDiv = (props) => {
  return <Modal show={props.show} onHide={() => props.onClose()} centered>
    <Modal.Header closeButton>
      <Modal.Title>{translate(props.appData, props.title)}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
    {props.isJSX ? props.message : <div
      dangerouslySetInnerHTML={{__html: translate(props.appData, props.message)}}
    />}
    </Modal.Body>

    {(props.showClose || props.onConfirm) && <Modal.Footer>
      {props.showClose && <Button variant="secondary" onClick={() => props.onClose()}>{translate(props.appData,props.closeTitle || `Close`)}</Button>}
      {props.onConfirm && <Button variant="primary" onClick={() => props.onConfirm()}>{translate(props.appData,props.confirmTitle || `Save changes`)}</Button>}
    </Modal.Footer>}
  </Modal>
}

export default ModalDiv;
