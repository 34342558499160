import React from 'react';
import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './App.css';
// import { translate } from "./utils";

const NavBar = (props) => {

  return <Navbar bg="light" expand="lg">
    <Container>
      <Navbar.Brand href="/"><img alt="Logo" width="250" src="logo.png"/></Navbar.Brand>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/settings">{translate(props.data, `Settings`)}</Nav.Link>
          <Nav.Link href="/help">{translate(props.data, `Help`)}</Nav.Link>
        </Nav>
      </Navbar.Collapse> */}
    </Container>
  </Navbar>
}

export default NavBar;
