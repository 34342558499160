import React,  { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
// import Button from 'react-bootstrap/Button';
import Button from 'react-bootstrap/Button';
import Navbar from './Navbar';
import Menu from './Menu';
import Donate from './Donate';
import Loan from './Loan';
import Onetime from './Onetime';
import GiftAid from './GiftAid';
import Exhibitor from './Exhibitor';
import AdultClasses from './AdultClasses';
import { ArrowLeft, CheckCircleFill } from 'react-bootstrap-icons';
import './App.css';
import Modal from './Modal';
import ReactGA from "react-ga4";
import { translate } from "./utils";
const version = `1.0.3`
//https://alquran.cloud/tajweed-guide
//https://github.com/dsog/jqurantree
//https://corpus.quran.com/java/example/locationexample.jsp

// const result = {"verseData":"إِ<span  id='q1' class=ghunnah>نّ</span>َ <span  id='q2' class=ham_wasl>ٱ</span>لَّذِينَ لَا يُؤْمِنُونَ بِ<span  id='q3' class=ham_wasl>ٱ</span>لْأَخِرَةِ لَيُسَ<span  id='q4' class=ghunnah>مّ</span>ُونَ <span  id='q5' class=ham_wasl>ٱ</span>لْمَلَ<span  id='q6' class=madda_obligatory>ـٰٓ</span>ئِكَةَ تَسْمِيَةَ <span  id='q7' class=ham_wasl>ٱ</span>لْأُ<span  id='q8' class=ikhafa>نث</span>","rules":[{"index":8,"rule":"ghunnah","text":"نّ"},{"index":39,"rule":"ham_wasl","text":"ٱ"},{"index":95,"rule":"ham_wasl","text":"ٱ"},{"index":141,"rule":"ghunnah","text":"مّ"},{"index":175,"rule":"ham_wasl","text":"ٱ"},{"index":210,"rule":"madda_obligatory","text":"ـٰٓ"},{"index":267,"rule":"ham_wasl","text":"ٱ"},{"index":300,"rule":"ikhafa","text":"نث"}]}

const App = () => {

  const [cookies, setCookie] = useCookies(['appData']);
  const [init, setInit] = useState(false);
  const [menuItem, setMenuItem] = useState('');
  const [modal, showModal] = useState({});
  const [showCorrect, setShowCorrect] = useState({});
  const [toastObject, setToastObject] = useState({visible: false});
  
  const selectMenu = (item, title, message) => {
    ReactGA.event({
      category: item,
      action: 'Menu',
      value: 1
    });
    setMenuItem(item);
    if (title && message) {
      showToast(title, message)
    }
  }

  function showModalWithMessage(title, message, showClose = true, callback, closeTitle, isJSX, onConfirm, confirmTitle) {
    showModal({
      show: true,
      title, 
      message,
      showClose,
      callback,
      appData: cookies.appData,
      closeTitle,
      isJSX,
      onConfirm,
      confirmTitle,
    });
  }

  function showConfirm(title, message, onConfirm, confirmTitle = 'Yes') {
    showModal({
      show: true,
      title, 
      message,
      appData: cookies.appData,
      confirmTitle,
      onConfirm,
    });
  }

  function showToast(title, message) {
    setToastObject({
      title,
      message,
      visible: true,
    })
  }

  function hideToast() {
    setToastObject({
      title: undefined,
      message: undefined,
      visible: false,
    })    
  }

  const showResponse = async (correct) => {
    return new Promise((resolve, _) => {
      setShowCorrect({
        visible: true,
        correct,
      })
      setTimeout(() => {
        setShowCorrect({
          visible: false,
          correct: undefined
        });
        resolve();
      }, 1500);  
    })  
  }

  useEffect(() => {
    if (!cookies.welcome) {      
      showModalWithMessage('Welcome to Al Falah Platform', `Please use this platform communicate with our centre`, true, () => {
        setCookie('welcome', {init: true, cookies: false, version}, { path: '/' });
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }
        };
        updateCookie(cookies.appData);
        window.location.reload();
      }, 'Necessary cookies', false, () => {
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }
        };
        updateCookie(cookies.appData);
        setCookie('welcome', {init: true, cookies: true, version}, { path: '/' });
        window.location.reload();
        hideModal();
      }, 'All Cookies');
    }    
    else if (!init) {
      cookies.welcome.version = version;
      setCookie('welcome', cookies.welcome);
      updateCookie(cookies.appData);
      setInit(true);
      if (cookies.welcome.cookies) {
        ReactGA.initialize('G-R9FQJDS5SN');
      }       
    }
    else if (!!window.location.search && menuItem.indexOf(window.location.search) === -1) {
      setMenuItem(window.location.search.substring(3))
    }
}, [cookies, setCookie, init, setInit, setMenuItem, menuItem]);

  const hideModal = () => {
    showModal({
      show: false,
      title: '', 
      message: ''
    });
  }

  function updateCookie(appData) {
    setCookie('appData', appData, { path: '/' });
  }

  const params = {
    selectMenu,
    showModalWithMessage,
    showResponse,
    updateCookie,
    showToast,
    setMenuItem,
    data: cookies.appData,
    ReactGA
  }
  
  return (
    <>     
      <Navbar data={cookies.appData} {...params} />    
      <Toast show={toastObject.visible} onClose={hideToast}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">{translate(cookies.appData,toastObject.title)}</strong>
      </Toast.Header>
      <Toast.Body>{translate(cookies.appData, toastObject.message)}</Toast.Body>
    </Toast>
      {modal.show && <Modal {...modal} showClose={modal.showClose} onClose={() => {
        hideModal();
        if (modal.callback) modal.callback(false, );
      }}/>}
      {showCorrect.visible && <div style={{border: '1px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', fontSize: '50px'}}><CheckCircleFill color="green"/></div>}
      {!menuItem 
        ? <Menu onClick={selectMenu} appData={cookies.appData}/> 
        : <Container className="mb-4 bg-white rounded-3">        
            <Button variant="secondary" onClick={
              () => showConfirm('Exit', 'Are you sure you want to go back?', () => {
                window.location.href = '/'
              })
            }><ArrowLeft/></Button>
            {menuItem === 'donate' && <Donate {...params}/>}
            {menuItem === 'loan' && <Loan {...params}/>}
            {menuItem === 'onetime' && <Onetime {...params}/>}
            {menuItem === 'giftaid' && <GiftAid {...params}/>}            
            {menuItem === 'exhibitor' && <Exhibitor {...params}/>}            
            {menuItem === 'adult' && <AdultClasses {...params}/>}            
          </Container>          
      }      
    </>
  );
}

export default App;
