import React,  { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Loading from './Loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { postResult } from "./utils";

const Loan = (props) => {
  
  const { ReactGA, selectMenu } = props;
  const [loading, setLoading] = useState(true);  
  const [submitted, setSubmitted] = useState(false);  
  const [name, setName] = useState('');  
  const [email, setEmail] = useState('');  
  const [phone, setPhone] = useState('');  
  const [amount, setAmount] = useState('');  
  const [length, setLength] = useState(-1);
  const [confirm, setConfirm] = useState(false);  
  const [ inited, setInited ] = useState(false);
  
  useEffect(() => {
    if (!inited) {
      setInited(true);
      setLoading(false);
    }    
  }, [inited, setInited]); // Or [] if effect doesn't need props or state

  function handleClick() {
    setLoading(true);
    const type = 'Loan';
    ReactGA.event({
      category: type,
      action: type,
      value: amount
    });
    return postResult({
      type,
      name,
      email,
      phone,
      length,
      amount,
    }).then(() => {
      setSubmitted(true);
      setLoading(false);
    })    
  }

  const disabled = !name || !email  || !amount || length === -1 || !confirm

  return loading ? <Loading/> : (<>
    <Container>   
    <h2>Qadr Hasanah</h2>
    {submitted ? <>
      Jazakallah khairun for your intention to lend us a hand!<br/><br/>
      <Button variant="primary" type="submit" onClick={() => selectMenu('')}>
        Return to Main Menu
      </Button>
      <br/><br/>We will be in touch regarding your intention
    </>
    :<Form>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Enter your full name:</Form.Label>
        <Form.Control type="text" placeholder="Full name" onChange={(ev) => setName(ev.target.value)} value={name}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Enter your email:</Form.Label>
        <Form.Control type="text" placeholder="Email address" onChange={(ev) => setEmail(ev.target.value)} value={email}/>
      </Form.Group>

      {/* <Form.Group className="mb-3" controlId="formPhoneNumber">
        <Form.Label>Enter your phone number:</Form.Label>
        <Form.Control type="text" placeholder="Phone number"  onChange={(ev) => setPhone(ev.target.value)} value={phone}/>
      </Form.Group> */}

      <Form.Group className="mb-3" controlId="formDonation">
        <Form.Label>How much do you intend to lend as Qadr Hasanah:</Form.Label>
        <Form.Control type="number" placeholder="Donation amount"  onChange={(ev) => setAmount(ev.target.value)} value={amount}/>
        <Form.Text className="text-muted">
          We are not taking payment. We are simply recording your intention.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formLength">
        <Form.Label>How long before we need to return</Form.Label>
        <Form.Select aria-label="Default select example" onChange={(ev) => setLength(parseInt(ev.target.value))}>
          <option value="-1">Please select</option>
          <option value="1">1 year</option>
          <option value="2">2 years</option>
          <option value="3">More than 2 years</option>
        </Form.Select>
        <Form.Text className="text-muted">
          
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" onClick={() => setConfirm(!confirm)} label="I confirm that I am making the niyaat to make the above qadr hasanah" />
      </Form.Group>
      <Button variant="primary" type="submit" onClick={handleClick} disabled={disabled}>
        Submit
      </Button>
    </Form>}       
    </Container>
    </>
);
}

export default Loan;
