export const language = [{
    text: 'Level',
	fr:'Liveau'
},{
	text:'Beginner',
	fr:'Débutant'
},{
	text:'Advanced',
	fr:'Avancé'
},{
	text:'Beginner level will use ayats from Juz Amma. Advanced will use ayats from the entire Quran.',
	fr:'Le niveau débutant utilisera les ayats de Juz Amma. Le niveau avance utilisera des ayats de l’ensemble du Coran.'
},{
	text:'Choose the rules you would like to be tested on:',
	fr:'Choisissez les règles sur lesquelles vous souhaitez être testé'
},{
	text:'Language',
	fr:'Langue'
},{
	text:'Practice rule',
	fr:'Règle de pratique '
},{
	text:'Find rule',
	fr:'Rechercher une règle'
},{
	text:'Which one?',
	fr:'Laquelle?'
},{
	text:'Find all!',
	fr:'Rechercher tout'
},{
	text:'Settings',
	fr:'Paramètres'
},{
	text:'Please contact <a href="mailto:support@tajweedapp.com">support@tajweedapp.com</a> for any queries',
	fr:'Veuillez contacter <a href="mailto:support@tajweedapp.com">support@tajweedapp.com</a> pour toute question'
},{
	text:'Each rule is shown below. Click on the Youtube icon to view a short video about the rule.',
	fr:'Chaque règle est présentée ci-dessous. Cliquez sur l’icône Youtube pour visionner une courte vidéo sur la règle en question.'
},{
	text:'Madd Munfasil (Short)',
	fr:'Madd Munfasil (courte)'
},{
	text:'Madd Muttasil (Long)',
	fr:'Madd Muttasil (longue)'
},{
	text:'You will be tested on a single rule. There will be 5 questions and you will have 3 tries to find an example of the rule. The ayats will come from %SOURCE%. If you need help, click on the ? icon to watch a short video about the rule.',
	fr:'Vous serez testé sur une seule règle. Il y aura 5 questions et vous aurez 3 tentatives pour trouver un exemple de la règle. Les ayats proviendront de %SOURCE%. Si vous avez besoin d’aide, cliquez sur le bouton ? pour visionner une courte vidéo sur la règle.'
},{
	text:'Juz Amma',
	fr:'Juz Amma'
},{
	text:'the Quran',
	fr:'Le Koran'
},{
	text:'Please find an example of the rule in the following ayah:',
	fr:'Veuillez trouver un exemple de la règle dans l’ayah suivant:'
},{
	text:`You've got %TRIES% tries left.`,
	fr:'Il vous reste %TRIES% tentatives.'
},{
	text:`You've got 1 try left.`,
	fr:'Il vous reste 1 tentative.'
},{
	text:'Examples of the correct answer are shown below.',
	fr:'Des exemples de la bonne réponse sont présentés ci-dessous.'
},{
	text:'Next Question',
	fr:'Question suivante'
},{
	text:'Tajweed Rules',
	fr:'Règles du Tajwid'
},{
	text:'Here is a short video explaining the rule:',
	fr:'Voici une courte vidéo expliquant la règle :'
},{
	text:'Find an example of the rule that has been asked. There will be 5 questions and you will have 3 tries to find the correct answer. The ayats will come from %SOURCE%.',
	fr:'Trouvez un exemple de la règle qui a été demandée. Il y aura 5 questions et vous aurez 3 tentatives pour trouver la bonne réponse. Les ayats proviendront de %SOURCE%.'
},{
	text:'Start',
	fr:'Commencer'
},{
	text:'Finished!',
	fr:'Terminé'
},{
	text:'You correctly answered %CORRECT% out of %TOTAL% questions.',
	fr:'Vous avez répondu correctement à %CORRECT% questions sur %TOTAL%.'
},{
	text:'Please select which rule has been highlighted. There will be 5 questions and you will have 3 tries to find the correct answer. The ayats will come from %SOURCE%.',
	fr:'Veuillez sélectionner la règle qui a été mise en surbrillance. Il y aura 5 questions et vous aurez 3 tentatives pour trouver la bonne réponse. Les ayats proviendront de %SOURCE%.'
},{
	text:'Which rule has been highlighted?',
	fr:'Quelle règle a été mise en évidence?'
},{
	text:'Please select',
	fr:'Veuillez sélectionner'
},{
	text:'Find all instances of the rule in the following ayah. You will have 3 tries. If an area of the ayah is not clickable, that means there are no rules there. The ayats will come from %SOURCE%.',
	fr:'Trouvez toutes les instances de la règle dans l’ayah suivant. Vous aurez 3 tentatives. Si vous ne pouvez pas cliquer dans une zone de l’ayah, cela signifie qu’il n’y a pas de règles. Les ayats viendront de %SOURCE%'
},{
	text:' Increase the level to Advanced to further test your skills.',
	fr:' Passez au niveau avancé pour tester vos compétences.'
},{
	text:'Masha Allah! It took you 1 try.',
	fr:'Masha Allah! Il vous a fallu 1 tentative seulement.'
},{
	text:'Masha Allah! It took you %TRIES% tries',
	fr:'Masha Allah! Il vous a fallu %TRIES% tentatives.'
},{
	text:'Exit',
	fr:'Quitter'
},{
	text:'Are you sure you want to go back?',
	fr:'Êtes-vous sûre de vouloir y retourner?'
},{
	text:'Yes',
	fr:'Oui'
},{
	text:'Close',
	fr:'Fermer'
},{
	text:'Save changes',
	fr:'Enregistrer les modifications'
},{
	text: `Help`,
	fr:  'Aide',
},{
	text: `Can you find one instance of `,
	fr: 'Pouvez-vous trouver un exemple de'
},{
	text: `Clear`,
	fr:  'Effacer',
},{
	text: `Submit`,
	fr: 'Soumettre'
}, {
	text: `The rule highlighted is `,
	fr: 'La règle est mise en surbrillance ' 
}, {
	text: `Incorrect`,
	fr: 'Réponse incorrecte'
}, {
	text: `Unfortunately you have run out of tries. Please try again.`,
	fr: 'Malheureusement, vous avez épuisé le nombre de tentatives permises, veuillez réessayer'
}, {
	text: `Find all instances of `,
	fr: 'Trouvez tous les exemples de'
}, {
	text: `Finish`,
	fr: 'Terminer'
}, {
	text: `You answered all %TOTAL% questions correctly!`,
	fr: 'Vous avez répondu correctement à toutes les questions %TOTAL%!'
}, {
	text: `Welcome to Tajweed App`,
	fr: ``
}, {
	text: `This app will test you only on the following rules using ayahs from the Quran:`,
	fr: ``
}, {
	text: `Please check out the Settings menu to customise the app`,
	fr: ``
}, {
	text: `Welcome Back!`,
	fr: ``
}, {
	text: `We've added 4 new rules. Check out the settings menu where you can manage your preferences including using the app en Français. We've also added Streaks! Practice at least once a day to maintain your streak!`,
	fr: ``
}, {
	text: `We've added two new rules (Lam Shamsiyahh and Madd Arid), a new game and translated most of the app in French. We've also added Streaks! Practice at least once a day to maintain your streak!`,
	fr: ``
}, {
	text: `We've added Streaks! Practice at least once a day to maintain your streak!`,
	fr: ``
}, {
	text: `Mission Complete!`,
	fr: ``
}, {
	text: `You're on track!`,
	fr: ``
}, {
	text: `You have achieved the next badge! To level up, achieve a streak of %NEXTSTREAK%!`,
	fr: ``
}, {
	text: `You have now practiced for %STREAK% days. Only %LEFT% days to achieve your next badge.`,
	fr: ``
}]


