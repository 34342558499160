import React,  { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Loading from './Loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { postResult } from "./utils";

const Onetime = (props) => {
  
  const { ReactGA, selectMenu  } = props;
  const [loading, setLoading] = useState(true);  
  const [submitted, setSubmitted] = useState(false);  
  const [name, setName] = useState('');  
  const [email, setEmail] = useState('');  
  const [phone, setPhone] = useState('');  
  const [amount, setAmount] = useState('');  
  const [confirm, setConfirm] = useState(false);  
  const [ inited, setInited ] = useState(false);
  const [uk, setUK] = useState(false);  
  const [address, setAddress] = useState('');  
  
  useEffect(() => {
    if (!inited) {
      setInited(true);
      setLoading(false);
    }    
  }, [inited, setInited]); // Or [] if effect doesn't need props or state

  function handleClick() {
    setLoading(true);
    const type = 'One-time';
    ReactGA.event({
      category: type,
      action: type,
      value: amount
    });
    return postResult({
      type,
      email,
      name,
      phone,
      amount,
      uk,
      address,
    }).then(() => {
      setSubmitted(true);
      setLoading(false);
    })    
  }

  const disabled = !name || !email || !amount || !confirm || (uk && !address)

  return loading ? <Loading/> : (<>
    <Container>   
    <h2>One-time donation</h2>
    {submitted ? <>
      Jazakallah khairun for your donation! Please submit your donation using the following bank details:<br/><br/>
      Al-Falah Islamic Education Centre<br/>
      Barclays Bank<br/>
      Sort code: 20-38-83<br/>
      Account: 50472689<br/>
      Payment Reference: Masjid Project<br/><br/>
      {uk && <strong><p>Thank you for filling out your Gift Aid declaration.</p></strong>}
      <Button variant="primary" type="submit" onClick={() => selectMenu('')}>
        Return to Main Menu
      </Button>
      <br/><br/>We will be in touch regarding your donation
    </>
    :<Form>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Enter your full name:</Form.Label>
        <Form.Control type="text" placeholder="Full name" onChange={(ev) => setName(ev.target.value)} value={name}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Enter your email:</Form.Label>
        <Form.Control type="text" placeholder="Email address" onChange={(ev) => setEmail(ev.target.value)} value={email}/>
      </Form.Group>

      {/* <Form.Group className="mb-3" controlId="formPhoneNumber">
        <Form.Label>Enter your phone number:</Form.Label>
        <Form.Control type="text" placeholder="Phone number"  onChange={(ev) => setPhone(ev.target.value)} value={phone}/>
      </Form.Group> */}

      <Form.Group className="mb-3" controlId="formDonation">
        <Form.Label>How much do you intend to donate:</Form.Label>
        <Form.Control type="number" placeholder="Donation amount"  onChange={(ev) => setAmount(ev.target.value)} value={amount}/>
        <Form.Text className="text-muted">
          We are not taking payment. We are simply recording your intention.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" onClick={() => setConfirm(!confirm)} label="I confirm that I am making the niyaat to make the above donation" />
      </Form.Group>
      <h2>Are you a UK Tax payer?</h2>
      <img src="giftaid.png" className="rounded me-2" alt="" />
      <Form.Group className="mb-3" controlId="formAddress">
        <Form.Label>Enter your full address:</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Home address"  onChange={(ev) => setAddress(ev.target.value)} value={address}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" onClick={() => setUK(!uk)} label="I want to gift aid this pledge and any donations I make in the future or have made in the past four years to Al-Falah Islamic Education Centre." />
      </Form.Group>
      <Button variant="primary" type="submit" onClick={handleClick} disabled={disabled}>
        Submit
      </Button>
    </Form>}       
    </Container>
    </>
);
}

export default Onetime;
