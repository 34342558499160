import React,  { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Loading from './Loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { postResult } from "./utils";

const GiftAid = (props) => {
  
  const { ReactGA, selectMenu  } = props;
  const [loading, setLoading] = useState(true);  
  const [submitted, setSubmitted] = useState(false);  
  const [name, setName] = useState('');  
  const [company, setCompany] = useState('');  
  const [contact, setContact] = useState('');  
  const [website, setWebsite] = useState('');  
  const [social, setSocial] = useState('');  
  const [email, setEmail] = useState('');  
  const [products, setProducts] = useState('');  
  const [extras, setExtras] = useState('');  
  const [confirm, setConfirm] = useState(false);  
  const [ inited, setInited ] = useState(false);
  
  useEffect(() => {
    if (!inited) {
      setInited(true);
      setLoading(false);
    }    
  }, [inited, setInited]); // Or [] if effect doesn't need props or state

  function handleClick() {
    setLoading(true);
    const type = 'Exhibitor';
    ReactGA.event({
      category: type,
      action: type,
      value: email
    });
    return postResult({
      type,
      name,
      company,
      contact,
      website,  
      email,
      social,
      products,
      extras,
    }).then(() => {
      setSubmitted(true);
      setLoading(false);
    })    
  }

  const disabled = !name || !company || !contact || (!website && !social) || !email || !products || !confirm

  return loading ? <Loading/> : (<>
    <Container>   
    <h2>Exhibitor Booking Form</h2>
    {submitted ? <>
      Jazakallah khairun for your submission. We will be in touch.<br/><br/>
    </>
    :<>
        <p>Thank you for expressing your interest to exhibit at the Family Eid Festival event on June 22nd 2024 from 12PM to 6PM. The cost to exhibit will be £75. Please fill in the following form. We will review your application and advise you of next steps. We look forward to meeting you soon. </p>
    <Form>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Full name:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setName(ev.target.value)} value={name}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCompany">
        <Form.Label>Company/organisation:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setCompany(ev.target.value)} value={company}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formContact">
        <Form.Label>Contact no:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setContact(ev.target.value)} value={contact}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formWebsite">
        <Form.Label>Website:</Form.Label>
        <Form.Control type="text" placeholder="Website" onChange={(ev) => setWebsite(ev.target.value)} value={website}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formSocial">
        <Form.Label>Social Media Handles:</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Enter your social media links"  onChange={(ev) => setSocial(ev.target.value)} value={social}/>
      </Form.Group>      

      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Email address:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setEmail(ev.target.value)} value={email}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formProducts">
        <Form.Label>Products / Services:</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Description of your Products / Services you will sell at the event"  onChange={(ev) => setProducts(ev.target.value)} value={products}/>
      </Form.Group>  

      <Form.Group className="mb-3" controlId="formExtras">
        <Form.Label>Any extras needed:</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Please state anything that you need on the day. We will do our best to accomodate."  onChange={(ev) => setExtras(ev.target.value)} value={extras}/>
      </Form.Group>  
      <h2>Terms & Conditions of booking</h2>
      <p>      
        <ul>
          <li>We recommend you arrive between 6PM and 8PM on Friday June 21st to unload your stock/equipment.</li>
          <li>The venue will be available to set up from 10.30 AM on Saturday June 22nd. Please arrive no later than 11 AM in order to be set up & be ready for the public opening at 12 PM</li>
          <li>Venue details: AL-FALAH EDUCATION CENTRE (NEW SITE) - 158 High St, West Drayton UB7 7BE</li>
          <li>No stall holders will be allowed to set up after 11.30 AM and will not be refunded.</li>
        </ul>
        <ul>
          <li>In the event of a cancellation made by the exhibitor four weeks or more prior to the scheduled event date, a refund of 50% of the total booking fee will be issued. </li>
          <li>No refund will be given for stalls cancelled 2 weeks prior to the event.</li>
          <li>No refund will be given due to exhibitors arriving late or if an exhibitor fails to turn up.</li>
        </ul>
        <ul>
          <li>A 6ft Table and Chairs will be provided. Please bring a white table cloth for your table.</li>
          <li>You must ensure that your stall is safe at all times – being aware of any potential hazards that may endanger yourself or the general public and take appropriate steps to minimise any identified risks. </li>
          <li>Power supply may be available on site for your stall. Please state on the form, if you require this. </li>
          <li>The Organisers will allocate your space according to site requirements and as we see fit for the event. </li>
          <li>The organiser’s will not accept responsibility for the level of trading during the event. </li>
          <li>Exhibitors must only sell goods or services as specified on their booking form. </li>
          <li>All Exhibitors will be expected to provide evidence of suitable liability insurance and any other relevant insurances and documentation where appropriate.</li>
          <li>The organisers accept no responsibility for security of any stands, equipment, stock, cash or any other property of Exhibitors.</li>
        </ul>
        <ul>
          <li>Please do not leave your stall unattended and if required to do so please speak to one of the organisers.</li>
          <li>Please ensure your stall is kept clean and tidy at all times and all rubbish is placed in the black bags provided.</li>
          <li>Please ensure you have tidied your space before leaving your stall at the end of the event.</li>
          <li>Please ensure that you are packed and ready to leave the premises by 7PM</li>
        </ul>


<p>For further information please email events@alfalahcentre.org</p>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" onClick={() => setConfirm(!confirm)} label="I confirm that I have read the terms and conditions and agree with them. My booking is not confirmed until I have received approval, made payment, sent proof of payment, and received a confirmation email regarding payment." />
      </Form.Group>
      </p>
      <Button variant="primary" type="submit" onClick={handleClick} disabled={disabled}>
        Submit
      </Button>
    </Form>
    </>}       
    </Container>
    </>
);
}

export default GiftAid;
