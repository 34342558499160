import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import './App.css';
import { translate } from "./utils";
import Container from 'react-bootstrap/Container';

const ButtonGroupMenu = (props) => {

  return <><div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',
      }}
    >
      <Container>
      <ButtonGroup vertical>
      {/* <Button onClick={() => props.onClick('exhibitor')} className="menuItem spacing">{translate(props.appData, 'Exhibitor Booking Form')}</Button> */}
      <Button onClick={() => props.onClick('adult')} className="menuItem spacing">{translate(props.appData, 'Adult Classes')}</Button>
        <Button onClick={() => props.onClick('giftaid')} className="menuItem spacing">{translate(props.appData, 'I want submit my Gift Aid declaration')}</Button>
        <Button onClick={() => props.onClick('donate')} className="menuItem spacing">{translate(props.appData, 'I would like to pledge a monthly donation')}</Button>
        <Button onClick={() => props.onClick('loan')} className="menuItem spacing">{translate(props.appData, 'I would like to give Qadr Hasanah')}</Button>
        <Button onClick={() => props.onClick('onetime')} className="menuItem spacing">{translate(props.appData, 'I want to make a one-time donation')}</Button>
        <Button onClick={() => window.open('https://www.alfalahcentre.org/donation.pdf')} className="menuItem spacing">{translate(props.appData, 'Download standing order form')}</Button>        
      </ButtonGroup>
      </Container>    
  </div>
        <footer>{translate(props.appData,`Please contact <a href="mailto:info@alfalahcentre.org">info@alfalahcentre.org</a> for any queries. Please remember to fill out a Gift Aid form that can help us claim an additional 25% of your donation.`, true)}</footer>               
  </>
}

export default ButtonGroupMenu;
