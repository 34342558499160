import React,  { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Loading from './Loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { postResult } from "./utils";

const GiftAid = (props) => {
  
  const { ReactGA, selectMenu  } = props;
  const [loading, setLoading] = useState(true);  
  const [submitted, setSubmitted] = useState(false);  
  const [name, setName] = useState('');  
  const [address, setAddress] = useState('');  
  const [email, setEmail] = useState('');  
  const [confirm, setConfirm] = useState(false);  
  const [ inited, setInited ] = useState(false);
  
  useEffect(() => {
    if (!inited) {
      setInited(true);
      setLoading(false);
    }    
  }, [inited, setInited]); // Or [] if effect doesn't need props or state

  function handleClick() {
    setLoading(true);
    const type = 'Giftaid';
    ReactGA.event({
      category: type,
      action: type,
      value: email
    });
    return postResult({
      type,
      name,
      email,
      address,
    }).then(() => {
      setSubmitted(true);
      setLoading(false);
    })    
  }

  const disabled = !name || !address || !email || !confirm

  return loading ? <Loading/> : (<>
    <Container>   
    <h2>Gift Aid declaration form</h2>
    {submitted ? <>
      Jazakallah khairun for your submission. <br/><br/>
      <Button variant="primary" type="submit" onClick={() => selectMenu('')}>
        Return to Main Menu
      </Button>
    </>
    :<>
        <p>Gift Aid It! Boost your donation by an extra 25% on anything you donate at no additional cost to you. </p>
    <p>We can reclaim gift aid from the tax you pay for the current tax year. We need the following details in order to claim gift aid. Your address is needed to identify you as a current UK taxpayer. </p>
    <p>I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on all my donations it is my responsibility to pay any difference.</p>
    <Form>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Enter your full name:</Form.Label>
        <Form.Control type="text" placeholder="Enter full name" onChange={(ev) => setName(ev.target.value)} value={name}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formAddress">
        <Form.Label>Enter your full address:</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Home address"  onChange={(ev) => setAddress(ev.target.value)} value={address}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Enter your email address:</Form.Label>
        <Form.Control type="text" placeholder="Email address" onChange={(ev) => setEmail(ev.target.value)} value={email}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" onClick={() => setConfirm(!confirm)} label="I want to gift aid my donations and any donations I make in the future or have made in the past four years to Al-Falah Islamic Education Centre." />
      </Form.Group>
      <Button variant="primary" type="submit" onClick={handleClick} disabled={disabled}>
        Submit
      </Button>
    </Form>
    </>}       
    </Container>
    </>
);
}

export default GiftAid;
