import React,  { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Loading from './Loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { postResult } from "./utils";

const GiftAid = (props) => {
  
  const { ReactGA, selectMenu  } = props;
  const [loading, setLoading] = useState(true);  
  const [submitted, setSubmitted] = useState(false);  
  const [name, setName] = useState('');  
  const [dob, setDOB] = useState('');  
  const [course, setCourse] = useState('');  
  const [contact, setContact] = useState('');  
  const [address, setAddress] = useState('');  
  const [email, setEmail] = useState('');  
  const [ inited, setInited ] = useState(false);
  
  useEffect(() => {
    if (!inited) {
      setInited(true);
      setLoading(false);
    }    
  }, [inited, setInited]); // Or [] if effect doesn't need props or state

  function handleClick() {
    setLoading(true);
    const type = 'AdultClasses';
    ReactGA.event({
      category: type,
      action: type,
      value: email
    });
    return postResult({
      type,
      name,
      dob,
      contact,
      address, 
      course, 
      email,
    }).then(() => {
      setSubmitted(true);
      setLoading(false);
    })    
  }

  const disabled = !course || !name || !address || !contact || !dob || !email

  return loading ? <Loading/> : (<>
    <Container>   
    <h2>Adult classes</h2>
    {submitted ? <>
      Jazakallah khairun for your submission. We will be in touch.<br/><br/>
    </>
    :<>
        <p>Thank you for expressing your interest in taking adult classes at Al-Falah. Please fill in the following form and we will get back to you. </p>
    <Form>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Full name:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setName(ev.target.value)} value={name}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCompany">
        <Form.Label>DOB:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setDOB(ev.target.value)} value={dob}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formSocial">
        <Form.Label>Address:</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Enter your home address"  onChange={(ev) => setAddress(ev.target.value)} value={address}/>
      </Form.Group>      

      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Email address:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setEmail(ev.target.value)} value={email}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formContact">
        <Form.Label>Contact no:</Form.Label>
        <Form.Control type="text" placeholder="" onChange={(ev) => setContact(ev.target.value)} value={contact}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formContact">
        <Form.Label>Course Options:</Form.Label>
        <Form onClick={(ev) => {
              setCourse(ev.target.id);
            }}>
        <Form.Check
            label="Arabic - £40/month - Every Sunday 2pm - 3:30pm"
            name="group1"
            type={'radio'}
            checked={course === 'arabic'}
            id={`arabic`}
          />
          <Form.Check
            label="⁠Quran Hifdh - £35/month Every Sunday 4pm - 6pm"
            checked={course === 'hifdh'}
            name="group1"
            type={`radio`}
            id={`hifdh`}
          />
          <Form.Check
            label="Quran Pronounciation & Tajweed - £30/month 6:30pm - 7:30pm"
            name="group1"
            type={`radio`}
            checked={course === 'tajweed'}
            id={`tajweed`}
          />
        </Form>
      </Form.Group>
      

      <Button variant="primary" type="submit" onClick={handleClick} disabled={disabled}>
        Submit
      </Button>
    </Form>
    </>}       
    </Container>
    </>
);
}

export default GiftAid;
