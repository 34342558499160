import { language } from './language'
import axios from 'axios';

export const getQueryParameters = () => {
    const tokens = window.location.search.substring(1).split("&");
    const result = {}
    tokens.forEach(x => {
      const two = x.split("=")
      if (two.length > 1) {
        result[two[0]] = two.slice(1).join("=")
      }
    })
    return result
  }

export const getLambdaUrl = () => {
    return (process.env.REACT_APP_API_ENV === 'dev'
    ? `https://4baed6dkj34cyiumjc2useiuzm0oepxj.lambda-url.eu-west-1.on.aws/`
    : `https://x4q4id7rgxbevdym6r7rncv3oe0cmqbm.lambda-url.eu-west-1.on.aws/`)
}

export const postResult = (body) => {
    const updated = {
        ...body,
        env: process.env.REACT_APP_API_ENV
    }
    return axios
      .post(getLambdaUrl(), updated);
}

export const translate = (data, text, isHTML = false, keys = {}) => {
    const entry = language.find(x => x.text === text);
    let result = text;
    if (entry && data) {
        result = entry[data.lang || "en"] || text
    }
    if (Object.keys(keys).length > 0) {
        Object.keys(keys).forEach((key) => {
            result = result.replace(`%${key.toUpperCase()}%`, translate(data, keys[key]));
        })
    }
    return isHTML 
        ? <div dangerouslySetInnerHTML={{__html: result}}/>
        : result
}